import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, notification, Select, Switch, Table, Tooltip } from 'antd';
import { Layout } from 'commons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const { Option } = Select;

/**
 * Modal component that is reused for both CREATE (POST) and UPDATE (PUT)
 */
const ChannelTokenModal = ({ visible, onClose, onSuccess, initialValues }) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const isEdit = Boolean(initialValues);

    // Whenever initialValues change, reset the form.
    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [initialValues, visible]);

    /**
     * Submit handler – decides between POST and PUT depending on `isEdit` flag.
     */
    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            if (isEdit) {
                await fetchAPI('PUT', '/channels_token', undefined, { ...values, id: initialValues.id });
            } else {
                await fetchAPI('POST', '/channels_token', undefined, values);
            }
            notification.success({ message: intl.formatMessage({ id: 'message.success' }) });
            onSuccess();
            onClose();
        } catch (err) {
            // If validation failed, `err` is a FieldError; otherwise API error.
            if (!err.errorFields) {
                notification.error({ message: intl.formatMessage({ id: 'error' }) });
            }
        }
    };

    /**
     * When the user changes the type we need to force re‑render to show/hide
     * provider‑specific fields. We keep the entire form controlled by Ant.
     */
    const type = Form.useWatch('type', form);

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            okText={intl.formatMessage({ id: 'save' })}
            onCancel={onClose}
            onOk={handleOk}
            open={visible}
            title={
                isEdit
                    ? `${intl.formatMessage({ id: 'edit' })} ${intl.formatMessage({ id: 'api_telephony.channel' })}`
                    : `${intl.formatMessage({ id: 'add' })} ${intl.formatMessage({ id: 'api_telephony.channel' })}`
            }
            width={600}
        >
            <Form form={form} initialValues={{ enabled: true, systemWide: false, ...initialValues }} layout='vertical'>
                <Form.Item label={<FormattedMessage id='storage.type' />} name='type' rules={[{ required: true }]}>
                    <Select placeholder={intl.formatMessage({ id: 'select' })}>
                        <Option value='gsm'>GSM</Option>
                        <Option value='sip'>SIP</Option>
                    </Select>
                </Form.Item>

                <Form.Item label={<FormattedMessage id='storage.name' />} name='name' rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item label={<FormattedMessage id='storage.available' />} name='enabled' valuePropName='checked'>
                    <Switch />
                </Form.Item>

                <Form.Item
                    label={<FormattedMessage id='api_telephony.systemWide' />}
                    name='systemWide'
                    valuePropName='checked'
                >
                    <Switch />
                </Form.Item>

                {type === 'gsm' && (
                    <React.Fragment>
                        <Form.Item label={<FormattedMessage id='api_telephony.keyBinotel' />} name='keyBinotel'>
                            <Input />
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='api_telephony.secretBinotel' />} name='secretBinotel'>
                            <Input />
                        </Form.Item>
                    </React.Fragment>
                )}

                {type === 'sip' && (
                    <React.Fragment>
                        <Form.Item
                            label={<FormattedMessage id='api_telephony.xProjectRingostat' />}
                            name='xProjectRingostat'
                            rules={[{ type: 'number', transform: value => (value ? Number(value) : undefined) }]}
                        >
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id='api_telephony.authKeyRingostat' />}
                            name='authKeyRingostat'
                        >
                            <Input />
                        </Form.Item>
                    </React.Fragment>
                )}
            </Form>
        </Modal>
    );
};

/**
 * Main page component – shows table with data and the modal to create / edit.
 */
const ApiTelephonyPage = () => {
    const intl = useIntl();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);

    /**
     * Fetch list of channel tokens.
     */
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetchAPI('GET', '/channels_token', null, null, { handleErrorInternally: true });
            setData(response);
        } catch (err) {
            // notification.error({ message: intl.formatMessage({ id: 'error' }) });
        } finally {
            setLoading(false);
        }
    }, [intl]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /**
     * Columns for the AntD table.
     */
    const columns = useMemo(
        () => [
            {
                title: '#',
                key: 'index',
                width: '5%',
                render: (_text, _record, index) => index + 1
            },
            {
                dataIndex: 'type',
                key: 'type',
                title: <FormattedMessage id='storage.type' />,
                width: '8%'
            },
            {
                dataIndex: 'name',
                key: 'name',
                title: <FormattedMessage id='storage.name' />,
                width: '15%'
            },
            {
                dataIndex: 'enabled',
                key: 'enabled',
                title: <FormattedMessage id='storage.available' />,
                width: '8%',
                render: value =>
                    value ? (
                        <CheckOutlined style={{ color: 'var(--green)' }} />
                    ) : (
                        <CloseOutlined style={{ color: 'var(--warning)' }} />
                    )
            },
            {
                dataIndex: 'systemWide',
                key: 'systemWide',
                title: <FormattedMessage id='api_telephony.systemWide' />,
                width: '10%',
                render: value =>
                    value ? (
                        <CheckOutlined style={{ color: 'var(--green)' }} />
                    ) : (
                        <CloseOutlined style={{ color: 'var(--warning)' }} />
                    )
            },
            {
                dataIndex: 'keyBinotel',
                key: 'keyBinotel',
                title: <FormattedMessage id='api_telephony.keyBinotel' />,
                width: '15%'
            },
            {
                dataIndex: 'secretBinotel',
                key: 'secretBinotel',
                title: <FormattedMessage id='api_telephony.secretBinotel' />,
                width: '15%'
            },
            {
                dataIndex: 'xProjectRingostat',
                key: 'xProjectRingostat',
                title: <FormattedMessage id='api_telephony.xProjectRingostat' />,
                width: '15%'
            },
            {
                dataIndex: 'authKeyRingostat',
                key: 'authKeyRingostat',
                title: <FormattedMessage id='api_telephony.authKeyRingostat' />,
                width: '15%'
            },
            {
                key: 'actions',
                width: '10%',
                render: (_text, record) => (
                    <React.Fragment>
                        <Tooltip title={<FormattedMessage id='edit' />}>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    setEditingRecord(record);
                                    setModalVisible(true);
                                }}
                                size='small'
                                style={{ marginRight: 8 }}
                                type='text'
                            />
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id='delete' />}>
                            <Button disabled icon={<DeleteOutlined />} size='small' type='text' />
                        </Tooltip>
                    </React.Fragment>
                )
            }
        ],
        [intl]
    );

    return (
        <Layout
            controls={
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setEditingRecord(null);
                        setModalVisible(true);
                    }}
                    type='primary'
                >
                    <FormattedMessage id='add' />
                </Button>
            }
            title={<FormattedMessage id='api_telephony' />}
        >
            <Table
                bordered
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={false}
                rowKey='id'
                size='small'
            />

            {modalVisible && (
                <ChannelTokenModal
                    initialValues={editingRecord}
                    onClose={() => setModalVisible(false)}
                    onSuccess={fetchData}
                    visible={modalVisible}
                />
            )}
        </Layout>
    );
};

export default ApiTelephonyPage;
